import { asNextApi, type NextApiHandler, RespondAsJson } from '@/pages/api/asNextAPI'
import { ClientSideCache } from '@/services/ClientSideCache'
import { SHOP_ORIGIN } from '@/services/Configuration'
import { assertion, string } from '@recoiljs/refine'
import Axios from 'axios'

const contextAwareResponse = (respondAsJson: RespondAsJson) => (
  response: unknown,
  maxAge: number = 60 * 60 * 1, // 1 h
) => {
  const responseBody = JSON.stringify(response)
  respondAsJson(200, {
    'Content-Length': Buffer.byteLength(responseBody),
    'Content-Type': 'application/json',
    'Cache-Control': `public, max-age=${maxAge}`,
    'Strict-Transport-Security': `max-age=${maxAge}`,
  })(response)
  return response
}

const assertStirng = assertion(string(), 'Query Param SKU must be a string')
export const setSSRCookie: NextApiHandler = async (ctx, respondAsJson) => {
  const experimentName = assertStirng(ctx.query.experimentName)
  const experimentValue = assertStirng(ctx.query.experimentValue)
  ctx?.res?.setHeader?.('Set-Cookie', `${experimentName}=${experimentValue}; max-age=31536000; SameSite=None; Secure;`)
  return contextAwareResponse(respondAsJson)({
    success: true,
  })
}

const setSSRCookieApi = asNextApi(setSSRCookie)

export default setSSRCookieApi

type SetInput = {
  experimentName: string
  experimentValue: string
}
const cacheExperiments = ClientSideCache('setSSRABCookie')((i:SetInput) => `${i.experimentName}-${i.experimentValue}`)

export const setSSRABCookie = cacheExperiments(({
  experimentName,
  experimentValue,
} : {
  experimentName: string
  experimentValue: string
}) => Axios.get(`${SHOP_ORIGIN}/ssr-api/set-ssr-ab-cookie?experimentName=${experimentName}&experimentValue=${experimentValue}`))
