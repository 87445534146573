import { useEffect, useState } from 'react'
import { error, log } from '@/services/Log'
import { FeatureToggle } from '@/services/Configuration'
import {
  array,
  assertion, bool, dict, object,
  string,
} from '@recoiljs/refine'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'
import type { ComposeActiveTest } from '@/types/ThirdPartyIntegrations/Compose'
import { withSentryFunctor } from '@/helpers/withSentryFunctor'
import { withExponentialBackoff } from '@/helpers/withExponentialBackoff'

export const assertComposeTestData = assertion(
  object({
    testAssignments: dict(object({
      experimentId: string(),
      assignedVariant: string(),
      orgId: string(),
      conversions: array(string()),
      visited: bool(),
    })),
  }), 'ComposeTestData is not a valid object',
)

const hasId = (target: string) => ({ id }: { id: string }) => id === target
const doNothaveId = (target: string) => ({ id }: { id: string }) => id !== target
const getLocalStorageComposeExperienceData = () => (
  typeof localStorage === 'undefined'
    ? null
    : localStorage.getItem('compose-experience-data')
)
const getComposeExpereiceData = () => (
  assertComposeTestData(JSON.parse(getLocalStorageComposeExperienceData() || '{ "testAssignments": {}}'))
)

const getActiveTests = (): ComposeActiveTest[] => {
  if (!window.compose?.activeTests) {
    throw new Error('window.compose.activeTests is not defined')
  }
  return window.compose.activeTests
}

const getOriginalVariantId = (test: ComposeActiveTest) => {
  const variant = test.variants.find(({ implementationType }) => implementationType === 'original')
  if (!variant) {
    throw new Error('Could not find original variant')
  }
  return variant.id
}
const getABOnSideVariantId = (test: ComposeActiveTest) => {
  const originalVariantId = getOriginalVariantId(test)
  const otherVariantId = test.variants.find(doNothaveId(originalVariantId))?.id
  if (!otherVariantId) {
    throw new Error('Could not find other variant')
  }
  return otherVariantId
}

export const getRunningTest = (experimentId: string) => {
  const activeTests = getActiveTests()
  const test = activeTests.find(hasId(experimentId))
  if (!test) {
    error('getRunningTest', { experimentId, activeTests })
    throw new Error(`Could not activeTest with experiment id: ${experimentId}`)
  }
  return test
}
const throwIfNotClientSide = () => {
  if (typeof window !== 'object') {
    throw new Error('window is not defined')
  }
  if (typeof localStorage === 'undefined') {
    throw new Error('localStorage is not defined')
  }
}

export const setAbTrigger = (experimentKey: string) => {
  if (!window.composeInterface?.triggerMap) {
    window.composeInterface = { triggerMap: {} }
  }
  window.composeInterface.triggerMap[experimentKey] = true
}
export const setAbSide = ({
  experimentId,
  experimentABOn,
  logPrefix,
  experimentTriggerKey,
}: {
  experimentTriggerKey: string,
  experimentId: string,
  experimentABOn: boolean,
  logPrefix?: string,
}) => {
  try {
    if (typeof window === 'undefined') {
      return
    }
    throwIfNotClientSide()
    log('composeABScriptLoaded setAbSide: started')
    const actuallySetAB = withSentryFunctor({
      functor: withExponentialBackoff(() => {
        log('setAbSide: composeABScriptLoaded running')
        if (window.composeInterface?.triggerMap?.[experimentTriggerKey]) {
          log(`${logPrefix || ''} composeABScriptLoaded Experiment ${experimentId} window.composeInterface?.triggerMap?.[experimentTriggerKey] visited, not setting AB side`.trim())
          return
        }
        const composeExperienceData = getComposeExpereiceData()
        if (composeExperienceData.testAssignments[experimentId]?.visited) {
          log(`${logPrefix || ''} composeABScriptLoaded Experiment ${experimentId} already visited, not setting AB side`.trim())
          return
        }
        const runningTest = getRunningTest(experimentId)
        const assignedVariant = (
          experimentABOn
            ? getABOnSideVariantId(runningTest)
            : getOriginalVariantId(runningTest)
        )
        log(`${logPrefix || ''} composeABScriptLoaded Setting AB side for experiment ${experimentId} to ${assignedVariant}`.trim())
        // eslint-disable-next-line @typescript-eslint/ban-types
        const currentExperiment: undefined | object = (
          composeExperienceData.testAssignments[experimentId]
        )
        const newData = {
          orgId: runningTest.orgId,
          experimentId,
          conversions: [],
          visited: false,
          ...currentExperiment,
          assignedVariant,
        }
        log(`${logPrefix || ''} composeABScriptLoaded data mutation`, {
          currentExperiment: currentExperiment ?? 'undefined',
          newData,
        })
        localStorage.setItem('compose-experience-data', JSON.stringify({
          ...composeExperienceData,
          testAssignments: {
            ...composeExperienceData.testAssignments,
            [experimentId]: newData,
          },
        }))
        setAbTrigger(experimentTriggerKey)
        window.compose?.executeExperiment(experimentId)
      }),
      sentryLabelOnError: 'setAbSide Failed',
      sentrContextOnError: {
        experimentId,
        experimentABOn,
        logPrefix,
        experimentTriggerKey,
        'window.compose': window.compose,
        'window.composeInterface': window.composeInterface,
      },
    })
    window.addEventListener('composeABScriptLoaded', () => {
      actuallySetAB(undefined).catch(error)
    }, { once: true })
    if (window.compose) {
      log('composeABScriptLoaded setAbSide: compose is already loaded')
      window.dispatchEvent(new Event('composeABScriptLoaded'))
    } else {
      log('composeABScriptLoaded setAbSide: compose is not loaded')
    }
  } catch (e) {
    error(`${logPrefix || ''} Error setting AB side: ${String(e)}`.trim(), e)
  }
}
// Documentation on how to implement VWO experiments is available on the file:
// types/ThirdPartyIntegrations/VWO.d.ts
export const getABExperiment = (experimentName: string) => {
  setAbTrigger(experimentName)
  const mapEntries = window.compose.activeTests.map((t) => [t.id, {
    name: t.name,
    variants: Object.fromEntries(t.variants.map((v) => [v.id, v.name] as [string, string])),
  }] as [string, { name: string, variants: Record<string, string> }])
  const map = Object.fromEntries(mapEntries)
  Object.keys(map).forEach((experimentId) => {
    try {
      window.compose.executeExperiment(experimentId)
    } catch (e) {
      error(`Error executing experiment ${experimentId}: ${String(e)}`, e)
    }
  })
  const composeExperimentData = getComposeExpereiceData()
  const assignedTestValues = (
    Object.values(composeExperimentData.testAssignments).filter((v) => v.visited)
  )
  const experiments = Object.fromEntries(
    assignedTestValues.map((v) => [
      map[v.experimentId].name,
      map[v.experimentId].variants[v.assignedVariant] === 'true',
    ] as [string, boolean]),
  )
  const experimentValue = !!experiments[experimentName]
  return experimentValue
}

export const getComposeAssignmentMap = (): Record<string, string> => {
  try {
    const map = Object.fromEntries(
      window.compose.activeTests.map((t) => [
        t.id,
        {
          name: t.name,
          variants: Object.fromEntries(t.variants.map((v) => [v.id, v.name] as [string, string])),
        },
      ] as [string, { name: string, variants: Record<string, string> }]),
    )
    const { testAssignments } = getComposeExpereiceData()
    const experiments = Object.fromEntries(
      Object.values(
        testAssignments,
      ).map((v) => [(map[v.experimentId].name), map[v.experimentId].variants[v.assignedVariant]]),
    )
    return experiments
  } catch (e) {
    error('Error getting compose assignment map', e)
    return {}
  }
}

export const useGetExperiment = ({
  experimentKey,
  featureToggle,
  forceOnFeatureToggle,
  forceValue,
}: {
  experimentKey: string,
  featureToggle?: FeatureToggle,
  forceOnFeatureToggle?: FeatureToggle,
  forceValue?: boolean,
}) => {
  const shouldForceValueOn = typeof forceValue === 'boolean' ? forceValue : null
  const shouldForceViaFeatureToggle = (
    forceOnFeatureToggle && isFeatureEnabled(forceOnFeatureToggle)
  ) ? true : null
  const [isExperimentOn, setIsExperimentOn] = useState<boolean | null>(
    shouldForceValueOn || shouldForceViaFeatureToggle || null,
  )
  useEffect(() => {
    if (typeof forceValue === 'boolean') {
      setIsExperimentOn(forceValue)
    }
  }, [forceValue])
  useEffect(() => {
    if (isExperimentOn !== null) return
    if (featureToggle && !isFeatureEnabled(featureToggle)) {
      setIsExperimentOn(false)
      return
    }
    if (!experimentKey) {
      setIsExperimentOn(false)
      return
    }
    setIsExperimentOn(getABExperiment(experimentKey))
  }, [isExperimentOn, experimentKey, featureToggle])
  return !!isExperimentOn
}

export default getABExperiment
