type MinReq = {
  headers?: Record<string, string | string[] | undefined>
}
export const getCookies = (req?: MinReq): string | undefined => {
  const cookieHeaders = (
    req?.headers?.cookie
    || req?.headers?.Cookie
    || req?.headers?.COOKIE
    || req?.headers?.cookies
    || req?.headers?.Cookies
    || req?.headers?.COOKIES
  )
  const cookieHeader = Array.isArray(cookieHeaders) ? cookieHeaders.join(';') : cookieHeaders
  if (cookieHeader) return cookieHeader
  if (typeof document !== 'undefined' && !cookieHeader) {
    return document.cookie
  }
  return cookieHeader
}

export const getCookieValue = (req?: MinReq) => (key: string): string | undefined => {
  const cookie = getCookies(req)
  if (!cookie) return undefined
  const cookieWithKey = (cookie.split(';').find((c) => c.trim().startsWith(`${key}=`)) || '').trim()
  const cookieValue = cookieWithKey.split('=')?.[1]?.trim()
  return cookieValue
}
