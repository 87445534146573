/* eslint-disable @typescript-eslint/no-unsafe-call */
import { error } from '@/services/Log'
import { thenCompose, thenThrown } from '@/helpers/FP'
import allPromisesWithRetries from './allPromisesWithRetries'

const sentryImporter = async () => {
  const [sentry] = await allPromisesWithRetries(() => [
    import('@sentry/react'),
  ])
  return sentry
}
type SentryreactPackage = Awaited<ReturnType<typeof sentryImporter>>

type SadPathConfig = {
  sentryLabelOnError: string,
  sentrContextOnError: Record<string, unknown>,
}

const entryToStringified = ([key, value]: [string, unknown]): [string, string] => (
  [key, JSON.stringify(value)]
)

const recordToStringifiedEntries = (record: Record<string, unknown>) : Record<string, string> => (
  Object.fromEntries(Object.entries(record).map(entryToStringified))
)

const sadPath = (
  sadPathPrps: SadPathConfig,
) => (runtimeError: unknown) => (
  thenCompose(({
    setContext, captureException,
  }: SentryreactPackage) => {
    setContext(
      sadPathPrps.sentryLabelOnError,
      recordToStringifiedEntries(sadPathPrps.sentrContextOnError),
    )
    captureException(String(`${sadPathPrps.sentryLabelOnError} | Error in withSentryFunctor reported to Sentry: ${String(runtimeError)}`))
    error('Error in withSentryFunctor reported to Sentry', sadPathPrps)
  })(sentryImporter)(null).catch(error)
)

export const withSentryFunctor = <T, K>({
  functor,
  ...sadPathPrps
}: SadPathConfig & {
  functor: ((x: T) => K | Promise<K>),
}) => thenThrown(functor)(sadPath(sadPathPrps))

export default withSentryFunctor
