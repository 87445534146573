import type { FeatureToggle } from '@/services/Configuration'
import { log } from '@/services/Log'
import { useEffect, useState } from 'react'
import { setSSRABCookie } from '@/pages/api/setSSRCookie'
import { getCookieValue } from './getCookieValue'
import { isFeatureEnabled } from './isFeatureEnabled'
import { setAbSide } from './getABExperiment'

export const getServerSideAbExperiment = ({
  cookieName,
  cookieFeature,
  ctx,
  experimentId,
  serverSideValue,
}: {
  ctx?: {
    req?: {
      headers?: Record<string, string | string[] | undefined>
    }
    res?: {
      setHeader?: (name: string, value: string) => void
    }
  },
  cookieName: string,
  cookieFeature: FeatureToggle,
  experimentId?: string,
  serverSideValue?: boolean,
}): boolean => {
  let serverSideExperimentValue = !!serverSideValue
  if (!isFeatureEnabled(cookieFeature)) {
    log(`getServerSideAbExperiment: ${cookieName} feature disabled`, {
      cookieName,
      cookieFeature,
    })
    return serverSideExperimentValue
  }
  const openSearchABCookie = getCookieValue(ctx?.req)(cookieName)
  if (openSearchABCookie) {
    serverSideExperimentValue = openSearchABCookie.includes('true')

    log(`getServerSideAbExperiment: ${cookieName} found`, {
      serverSideExperimentValue,
      openSearchABCookie,
      cookieName,
      cookieFeature,
    })
  } else {
    log('getServerSideAbExperiment: no cookie found', {
      'openSearchABCookie': openSearchABCookie,
      'req.headers': ctx?.req?.headers ? JSON.stringify(ctx?.req?.headers) : 'undefined',
      'typeof window': typeof window,
    })
    serverSideExperimentValue = (
      typeof serverSideValue === 'boolean'
        ? serverSideValue
        : Math.random() > 0.5
    )
    ctx?.res?.setHeader?.('Set-Cookie', `${cookieName}=${String(serverSideExperimentValue)}; max-age=31536000; SameSite=None; Secure;`)
    if (typeof window !== 'undefined') {
      setSSRABCookie({
        experimentName: cookieName,
        experimentValue: String(serverSideExperimentValue),
      })
    }

    log(`getServerSideAbExperiment: ${cookieName} assigned`, {
      serverSideExperimentValue,
      openSearchABCookie,
      cookieName,
      cookieFeature,
    })
  }
  if (experimentId) {
    setAbSide({
      experimentId,
      experimentABOn: serverSideExperimentValue,
      logPrefix: cookieName,
      experimentTriggerKey: cookieName,
    })
  }
  return serverSideExperimentValue
}

export const useServerSideAbExperiment = (p: {
  cookieName: string,
  cookieFeature: FeatureToggle,
  ctx?: {
    req?: {
      headers?: Record<string, string | string[] | undefined>
    }
    res?: {
      setHeader?: (name: string, value: string) => void
    }
  },
  experimentId?: string,
  serverSideValue?: boolean,
}) => {
  const [experimentValue, setExperimentValue] = useState(false)
  useEffect(() => {
    setExperimentValue(getServerSideAbExperiment(p))
  }, [p])
  return experimentValue
}
