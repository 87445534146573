import { error } from '@/services/Log'
import type { NextPage, NextPageContext } from 'next'

type Headers = NodeJS.Dict<number | string | string[]>
export type RespondAsJson = (status: number, headers: Headers) => (obj: unknown) => void

const getDefaultHeaders = (body: unknown): Headers => ({
  'Content-Length': Buffer.byteLength(JSON.stringify(body)),
  'Content-Type': 'application/json',
  'cache-control': 'public, s-maxage=900',
})
export const respondAsJson = (
  res: NextPageContext['res'],
  setResponded?: () => void,
) => (status: number, headers?: Headers) => (body: unknown) => {
  if (setResponded) {
    setResponded()
  }
  const bodyStr = JSON.stringify(body)
  if (res?.writable) {
    const headersToSend = headers || getDefaultHeaders(body)
    res?.writeHead(status, headersToSend).end(bodyStr)
  }
}

export const respondAsInternalError = (res: NextPageContext['res'], err: unknown) => {
  try {
    const errorStr = JSON.stringify({
      error: String(err),
    })
    error(`API Handler failed with: ${String(err)}`, err)
    if (res?.writable) {
      res?.writeHead(500, {
        'Content-Length': Buffer.byteLength(errorStr),
        'Content-Type': 'text/plain',
        'cache-control': 'public, must-revalidate, proxy-revalidate, max-age=0, s-maxage=0',
      }).end(errorStr)
    }
  } catch (err2) {
    error(`Failed to return 500 to ${String(err)} due to ${String(err2)}`, err, err2)
  }
}

export type NextApiHandlerContext = Omit<NextPageContext, 'AppTree'>
export type NextApiHandler = (
  ctx: NextApiHandlerContext,
  respondAsJson: RespondAsJson
) => Promise<unknown> | unknown

export const getDefaultIniitalProps = (
  handler: NextApiHandler,
) => async (ctx: NextPageContext) => {
  try {
    let responded = false
    const setResponded = () => { responded = true }
    const body = await handler(ctx, respondAsJson(ctx.res, setResponded))
    if (!responded) {
      respondAsJson(ctx.res)(200)(body)
    }
  } catch (err) {
    respondAsInternalError(ctx.res, err)
  }
}

export const asNextApi = (
  handler: NextApiHandler,
): NextPage => {
  const Component: NextPage = () => null
  Component.getInitialProps = getDefaultIniitalProps(handler)
  return Component
}

export default asNextApi
